
import { Options, Vue } from "vue-property-decorator";
import { namespace } from "s-vuex-class";
import { DEFAULT_DATE_FORMAT } from "@/config/Formats";

const TheoryPreExamListModule = namespace("theory-pre-exam-list");
@Options({})
export default class PreExamList extends Vue {
  @TheoryPreExamListModule.Action("findAll")
  public findPreExamsAction: any;

  @TheoryPreExamListModule.Getter("getDataList")
  public preExams: any;

  @TheoryPreExamListModule.Getter("getIsLoading")
  public loading: any;

  private dateFormat = DEFAULT_DATE_FORMAT;

  public mounted(): void {
    this.findPreExamsAction({
      resource: "theory-pre-exams",
      extraPath: "student/current",
      hideSuccessMessage: true,
    });
  }

  private onClickRow(preExamId: any) {
    if (preExamId) {
      this.$router.push({
        name: "FinishedPreliminaryExam",
        params: {
          id: preExamId,
        },
      });
    }
  }
}
