import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForwardHeader = _resolveComponent("ForwardHeader")!
  const _component_PreliminaryBlock = _resolveComponent("PreliminaryBlock")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_pre_exam_list = _resolveComponent("pre-exam-list")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_PlaygroundBlock = _resolveComponent("PlaygroundBlock")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    header: _withCtx(() => [
      _createVNode(_component_ForwardHeader)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { xl: "9" }, {
            default: _withCtx(() => [
              _createVNode(_component_PreliminaryBlock)
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            xl: "3",
            class: "mt-4 mt-xl-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_pre_exam_list)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, { class: "mt-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_PlaygroundBlock)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}