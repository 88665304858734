
import { Options, Watch } from "vue-property-decorator";
import { namespace } from "s-vuex-class";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import PreliminaryImg from "@/components/Pages/PreliminaryExam/PreliminaryImg.vue";
import { mixins } from "vue-class-component";
import StudentEducationsStoreMixin from "@/mixins/StudentEducations/StudentEducationsStoreMixin.ts";
import StudentPreExamEducationMixin from "@/mixins/StudentEducations/StudentPreExamEducationMixin";
import { useIonRouter } from "@ionic/vue";
import { IStudentEducationWithTheory } from "@/interfaces/IStudentEducationWithTheory";

const StudentEducationModule = namespace("student-education");

@Options({
  components: { PreliminaryImg, EducationTabs },
})
export default class PreliminaryBlock extends mixins(StudentPreExamEducationMixin, StudentEducationsStoreMixin) {
  private ionRouter = useIonRouter();

  @StudentEducationModule.Getter("withTheory/getDataList")
  public studentEducationWithTheory!: Array<IStudentEducationWithTheory>;

  public filteredEducations: any = [];

  private onEducationClassChanged(educationClass: any): void {
    if (educationClass && educationClass.id) {
      this.selectEducation(educationClass);
    }
  }

  private get preExamProgress() {
    return this.selectedEducation?.consecutiveSuccessfulPreExams || 0;
  }

  public onClickPreliminaryExamCard(): void {
    this.ionRouter.navigate(
      {
        name: "TheoryPreExams",
        params: {
          studentEducation: this.selectedEducation ? this.selectedEducation.id : 0,
        },
      },
      "forward",
      "push"
    );
  }

  public get selectedStudentEducation(): IStudentEducationWithTheory | null {
    return this.selectedEducation;
  }

  @Watch("studentEducations", { deep: true, immediate: true })
  public onStudentEducationsWatch(educations: any) {
    if (educations) {
      this.filteredEducations = educations.filter((education: any) => education.theoryRequired);
    }
  }
}
